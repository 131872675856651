<template>

	<div class="bg-container page">
		<div class="bg-wrapper">
			<div class="register">
				<van-nav-bar class="nav-bar">
					<template #left>
						<van-icon name="arrow-left" color="#e6b98a" @click="back()" />
					</template>
					<template #title>
						<van-ellipsis>{{ $t("auth.no_account") }}</van-ellipsis>
					</template>

				</van-nav-bar>
				<div class="wrapper">
					<div class="loginForm">
						<van-field v-model="username" :label="$t('auth.account')" required input-align="left" class="input" :placeholder="$t('auth.username_place2')" />
						<van-field v-model="password" type="password" :label="$t('auth.password')" required input-align="left" class="input" :placeholder="$t('auth.pwd_place2')" />
						<van-field v-model="code" :label="$t('auth.invite')" required input-align="left" class="input" :placeholder="$t('auth.invite_code_place')" />
						<van-button class="login-btn" type="primary" size="normal"
							@click="doRegister()">{{ $t("auth.register") }}</van-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	model: {
		prop: 'inputValue',
		event: 'input'
	},
	props: {
		/**
		 * 当前输入的值
		 */
		inputValue: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			checked: true,
			username: '',
			code: '',
			lang: 'en_us',
			password: this.inputValue,
			passwordType: 'password',
		};
	},
	mounted() {
		this.lang = localStorage.getItem("lang") || 'en_us';
	},
	methods: {
		switchPasswordType() {
			this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
		},
		back() {
			return window.history.back();
		},
		doRegister() {
			if (this.username === "" || this.username === null || this.username === undefined) {
				this.$toast.fail(this.$t("auth.username_place"));
				return false;
			}
			if (this.password === "" || this.password === null || this.password === undefined) {
				this.$toast.fail(this.$t("auth.pwd_place"));
				return false;
			}
			if (this.code === "" || this.code === null || this.code === undefined) {
				this.$toast.fail(this.$t("auth.invite_code_place"));
				return false;
			}
			// if(!this.checked){
			//   this.$toast.fail(this.$t("auth.agreement"));
			//   return false;
			// }
			this.$http({
				method: 'post',
				data: {
					username: this.username,
					password: this.password,
					code: this.code,
					lang: this.lang
				},
				url: 'member_register'
			}).then(res => {
				if (res.code === 200) {
					this.$toast.success(res.msg);
					localStorage.setItem('token', res.data)
					this.$router.push("Home")
				} else {
					this.$toast.fail(res.msg);
				}
			})
		}
	},
	created() {
		if (localStorage.getItem('token')) {
			return window.history.back();
		}
	}
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.register {
	height: 100%;
}

.bg-container {
	background: #282828;

	.bg-wrapper {
		background: none;

		.register .nav-bar {
			background: 0 0
		}
	}
}

.register .wrapper {
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.logo-container {
	margin: 0 auto;
	width: 45%;
}

.logo-container .logo-wrapper {
	position: relative;
	padding-bottom: 62.5%;
}

.logo-container .logo-wrapper .logo-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-o-object-fit: contain;
	object-fit: contain;
}

.register .wrapper .title {
	line-height: 50px;
	text-align: center;
	font-size: 20px;
	font-weight: 700;
	color: #fff;
	letter-spacing: 5px;
}

.register .wrapper .loginForm {
	padding: 20px;
}

.register .wrapper .loginForm .input {
	padding: 10px 20px;
    margin-top: 15px;
    text-align: left;
    line-height: 40px;
    font-size: 14px;
    color: #4e4e4e;
    background: none;
}

::v-deep .van-field__right-icon .van-icon {
	font-size: 50px;
}

::v-deep .van-icon {
	font-size: 20px;
}
::v-deep .van-nav-bar__title {
	color:#e6b98a;
}
::v-deep .van-cell--required::before {
	font-size: 14px;
	line-height: 45px;
}
::v-deep .van-field__label {
	width: 72px;
    padding-right: 10px;
    padding-left: 5px;
    color: #fff;
    font-size: 14px;
}
::v-deep .van-field__control {
	font-size: 14px;
	color: #fff;
}

.register .wrapper .loginForm .reset-text {
	margin: 30px 15px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.register .wrapper .loginForm .reset-text span {
	color: #fff;
	font-size: 25px;
	font-weight: 500;
	line-height: 15px;
}

.register .wrapper .loginForm .register-text {
	margin: 10px 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.register .wrapper .loginForm .register-text span {
	color: #fff;
	font-size: 25px;
	font-weight: 500;
	line-height: 20px;
}

.register .wrapper .loginForm .login-btn {
	margin-top: 30px;
    width: 100%;
    height: 50px;
    color: #fff;
    background: linear-gradient(#817f7f, #b7b5b5);
    font-size: 18px;
    font-weight: bolder;
    border: none;
}

.register .wrapper .loginForm .agreement {
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.register .wrapper .loginForm .agreement .agreement-text {
	margin-left: 10px;
	font-size: 25px;
	color: #fff;
	font-weight: 500;
	line-height: 30px;
}

::v-deep .agreement .van-icon {
	font-size: 30px;
}

::v-deep .agreement .van-checkbox__icon {
	font-size: 38px;
}

::v-deep .agreement .van-checkbox__icon--checked .van-icon {
	color: #fff;
	border-color: rgb(126, 86, 120);
	background-color: rgb(126, 86, 120);
}
</style>
