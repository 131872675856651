<template>
	<div class="container page">
		<van-nav-bar :title="this.videoInfo.vod_name" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="movie-video">
			<video id="my-video" class="video-js">
			</video>
		</div>
		<div class="movie-content">
			<div class="movie-descript">
				<p>{{ this.videoInfo.vod_name }}</p>
			</div>

			<div class="movie-body">
				<div class="movie-title">
					<div>
						<span>{{ $t("index.recmonmand") }}</span>
					</div>
				</div>
				<div class="movie-list">
					<div class="movie-play-item" @click="toPlayVideo(v.id)" v-for="(v, key) in moreVideoInfo" :key="key">
						<div>
							<img :src="v.vod_pic">
						</div>
						<div>
							<p>{{ v.vod_name }}</p>
							<span>{{ v.count }}{{ $t("video.num_play") }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import videojs from "video.js";
import "videojs-contrib-hls";
export default {
	data() {
		return {
			nowPlayVideoUrl: "",
			cover: "",
			userInfo: [],
			videoInfo: {},
			moreVideoInfo: {},
			player: null,
			is_play: false,
			times: null,
			is_see: 0
		};
	},
	methods: {
		back() {
			this.$router.push({ path: 'Video' })
		},
		getVideoInfo() {

			this.$http({
				method: 'get',
				data: { id: this.$route.query.id },
				url: 'video_get_info'
			}).then(res => {
				this.videoInfo = res.data;
				this.nowPlayVideoUrl = this.videoInfo.vod_play_url;
				this.cover = this.videoInfo.vod_pic;
				let videos = document.getElementById('my-video');
				videos.poster = this.cover;
				this.getVideo();
			})

		},
		toPlayVideo(id) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' })
			} else {
				this.$router.push({ path: '?id=' + id })
				location.reload();
			}

		},
		getMoreVideoItem() {
			this.$http({
				method: 'get',
				url: 'video_get_more_item'
			}).then(res => {
				this.moreVideoInfo = res.data;
			})
		},
		getVideo() {
			// this.player = videojs("my-video",  {
			//   height:"200px",
			//   preload: "auto", // 预加载
			//   controls: true,  // 显示播放的控件
			//   multipleArray: [0.75, 1, 1.5, 2], // 倍速设置
			// });
			this.player.src([{
				src: this.nowPlayVideoUrl,
				type: "application/x-mpegURL" // 告诉videojs,这是一个hls流
			}]);
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'user_info'
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
					this.is_see = this.userInfo.is_see;
					if (this.userInfo.status !== 1) {
						this.$toast(this.$t("video.account_out"));
						localStorage.clear()
						this.$router.push({ path: '/Login' })
					} else {
						if (this.$store.getters.getBaseInfo.isplay == 1) {
							this.getVideoInfo();
							this.getMoreVideoItem()
							// if(this.userInfo.money <= "0.00"){
							//   this.$toast(this.$t("video.buy"));
							//   this.$router.push({path:'/Home'})
							// }
						} else {
							this.getVideoInfo();
							this.getMoreVideoItem();
						}
					}
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			})
		},
	},
	mounted() {
		const _this = this;
		if (!localStorage.getItem('token')) {
			this.$router.push({ path: '/Login' })
		} else {
			this.player = videojs("my-video", {
				height: "200px",
				preload: "auto", // 预加载
				controls: true,  // 显示播放的控件
				multipleArray: [0.75, 1, 1.5, 2], // 倍速设置
			}, function () {
				this.on("play", () => {
					_this.is_play = true;
				});
			});
			this.getUserInfo();
			this.times = setInterval(() => {
				if (this.is_play && this.is_see == 0) {
					const ct = Math.round(this.player.currentTime())
					if (ct >= 180) {
						this.player.pause()
						this.$toast(this.$t("video.buy"));
						return
					}
				}
			}, 1000 * 2);
		}

	},

	destroyed() {
		if (this.is_play) {
			this.is_play = false;
		}
		clearInterval(this.times);
	}
};
</script>

<style scoped>
.container {
	background: #282828;
	height: 100vh;
}

::v-deep .van-nav-bar {
	height: 50px;
	background: none;
	color: #e6b98a;
}

::v-deep .van-nav-bar__title {
	color: #e6b98a;
}

::v-deep .van-hairline--bottom::after {
	border: none;
}

.nav-bar {
	height: 50px;

}

.van-nav-bar {
	line-height: 50px;
}


::v-deep .van-tab__text--ellipsis {
	overflow: unset;
}

.video-js {
	width: 100%;
	/*height: 420px;*/
	font-size: 12px;
}

.movie-content {
	flex: 1;
	overflow-y: auto;
}

.movie-content .movie-descript {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 15px;
}

.movie-content .movie-descript p {
	font-size: 14px;
	font-weight: 700;
	color: #fff;
}

.movie-content .movie-descript span {
	color: #979799;
}

.movie-content .movie-body {
	width: calc(100% - 20px);
	margin: 0 auto;
}

::v-deep .movie-video .video-js .vjs-big-play-button {
	top: 50%;
	left: 50%;
	margin-top: -35px;
	margin-left: -50px;
}

.movie-content .movie-body .movie-title {
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.movie-content .movie-body .movie-title>div:first-child {
	width: 100px;
	height: 26px;
	border-radius: 13px;
	background: linear-gradient(90deg, #efcba7, #dcab76);
	display: flex;
	align-items: center;
	justify-content: center;
}

.movie-content .movie-body .movie-title>div:first-child span {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 14px;
	font-weight: 700;
	color: #8a5623;
}

.movie-play-item {
	width: 100%;
	height: 110px;
	border-radius: 0px;
	position: relative;
	display: flex;
	margin-bottom: 10px;
}

.movie-play-item>div {
	height: 110px;
}

.movie-play-item>div:first-child {
	width: 110px;
	position: relative;
}

.movie-play-item>div:first-child>img {
	width: 100%;
	height: 100%;
	border-radius: 0;
}

.movie-play-item>div:first-child>div {

	width: 100%;
	height: 30px;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	background-color: rgba(0, 0, 0, .4);
	border-radius: 0 0 0 10px;
}

.movie-play-item>div:first-child>div .van-count-down {
	color: #fff;
	font-size: 25px;
}

.movie-play-item>div:nth-child(2) p {
	width: 250px;
	font-size: 14px;
	line-height: 32px;
	overflow: hidden;
	color: #fff;
}

.movie-play-item>div:nth-child(2) span {
	color: #fff;
	font-size: 12px;
}

.movie-play-item>div:nth-child(2) {
	padding: 0 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
}

abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	box-sizing: border-box;
}

.vjs-big-play-button .vjs-icon-placeholder {
	font-size: 16px !important;
}
</style>