<template>
	<div class="container recharge-index page">
		<div class="header">
			<van-nav-bar :title="$t('recharge.recharge')" class="nav-bar">
				<template #left>
					<van-icon name="arrow-left" color="#e6b98a" @click="back()" />
				</template>
				<template #right>
					<span class="nav-right" @click="$router.push({ path: '/RechargeRecord' })">{{ $t("my.recharge_record")
						}}</span>
				</template>
			</van-nav-bar>
			<div class="user" >
				<div class="user-info" >
					<div class="van-image avatar" ><img
							src="https://change.dsbxjpnmmp.me:5536/head/en/16.png" class="van-image__img">
					</div>
					<div class="info" >
						<p >{{ userInfo.username }}</p>
						<p >余额: {{ userInfo.money }} 积分</p>
					</div>
				</div>
				<div class="description" style="color: #fff;">
					请联系专属接待或顾问充值积分
					,请您在付款时确认</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import { Form } from "vant";
import { Field } from "vant";
import { RadioGroup, Radio } from "vant";
Vue.use(Form).use(Field).use(RadioGroup).use(Radio);
export default {
	data() {
		return {
			balance: 0,
			pay_way: "Mexicopay",
			win_money: 0,
			money: "",
			personalreport: {},
			userInfo:[]
		};
	},
	mounted() {
		this.balance = this.$route.params.balance;
	},
	methods: {
		back() {
			return window.history.back();
		},

		//拉起支付
		onSubmit(values) {
			const money = values.money;
			if (money <= 0) {
				this.$toast(this.$t("reservation.money_err"));
				return;
			}
			this.$http({
				method: "post",
				data: {
					pay_way: this.pay_way,
					money: money,
				},
				url: "recharge",
			}).then((res) => {
				console.log(res);
				if (res.code === 200) {
					window.location.href = res.data.pay_url;
					//   this.personalreport = res.data;
					//   this.win_money =
					//     this.personalreport.win_money - this.personalreport.play_money;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'user_info'
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			})
		},

		getPersonalreport() {
			this.$http({
				method: "get",
				url: "user_get_personalreport",
			}).then((res) => {
				if (res.code === 200) {
					this.personalreport = res.data;
					this.win_money =
						this.personalreport.win_money - this.personalreport.play_money;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
	},
	created() {
		if (!localStorage.getItem("token")) {
			this.$router.push({ path: "/Login" });
		} else {
			this.getUserInfo();
			this.getPersonalreport();
		}
	},
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";


.nav-bar .right {
	padding-left: 8px;
	padding-right: 8px;
	color: #e6b98a;
	font-size: 18px;
	line-height: 50px;
}
.nav-right{
	font-size: 14px;
	color: #fff;
}

::v-deep .van-nav-bar {
	height: 50px;
	background: none;
	color: #e6b98a;
}
::v-deep .van-nav-bar__title {
	color: #e6b98a;

}
::v-deep .van-hairline--bottom::after{
  border: none;
}
::v-deep .van-icon {
	font-size: 16px;
	color:#e6b98a;
}
.recharge-index .user{
	width: 100%;
    padding: 12px 16px;
    background: linear-gradient(90deg, #e6b98a, #e6b98a);
}
.recharge-index .user .user-info{
	display: flex;
    flex-direction: row;
}

.recharge-index .user .user-info .avatar{
	border-radius: 50%;
    width: 55px;
    height: 55px;
    overflow: hidden;
}
.recharge-index .user .user-info .info{
	display: flex;
    flex-direction: column;
    margin-left: 10px;
    justify-content: space-around;
}
.recharge-index .user .user-info .info p{
	color: #fff;
    font-size: 16px;
    line-height: 22px;
	margin: 0;
}



.recharge {
	padding: 10px 30px;
}

.van-cell {
	line-height: 65px !important;
}

.van-button {
	height: 87px !important;
}

.van-button__text {
	color: #fff !important;
}

/deep/.van-radio__icon {
	font-size: 30px !important;
}

/deep/.van-radio__label {
	margin-left: 25px !important;
	font-size: 35px !important;
}

/deep/.van-radio {
	height: 65px !important;
}

.form-item {
	margin-top: 40px;
}

.form-item-title {
	font-size: 36px;
	font-weight: bold;
	color: #999;
	margin-bottom: 20px;
}

.recharge span {
	font-size: 4vw;
	color: #868686;
	font-weight: 500;
}

.container .header .info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 20px;
	padding-top: 10px;
	margin: auto;
}

.container .header .info .title {
	font-size: 25px;
	color: #e5e5e5;
}

.container .header .info .value {
	margin: 10px auto;
	color: #fff;
	font-size: 50px;
	border-bottom: 1px solid #fff;
}

.container .header .info .tip {
	font-size: 30px;
	color: #e5e5e5;
}

.container .content {
	flex: 1;
	background: #f2f2f5;
}
</style>