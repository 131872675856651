<template>
	<div class="home-container">
		<van-nav-bar class="nav-bar" :title="this.$store.getters.getBaseInfo.name" />
		<div class="home-scroll">
			<div class="banner">
				<swiper class="banner_swiper" :options="bannerSwiperOption">
					<swiper-slide v-for="(v, key) in banners" :key="key">
						<van-image class="banner_img" round :src="v.url">
							<template v-slot:loading>
								<van-loading type="circular" />
							</template>
						</van-image>
					</swiper-slide>
				</swiper>
			</div>
			<div class="describe">
				<ul>
					<li>
						<div>{{ this.$store.getters.getBaseInfo.name }}</div>
						<div>{{ this.$store.getters.getBaseInfo.biaoyu }}</div>
					</li>
					<li>{{ this.$store.getters.getBaseInfo.des1 }}</li>
				</ul>
				<div>{{ this.$store.getters.getBaseInfo.des2 }}</div>
			</div>

			<van-tabs v-model="active" animated @change="OnChange">
				<van-tab key="0" title="资源" name="0"></van-tab>
				<van-tab key="1" title="实时配对" name="1"></van-tab>
				<van-tab key="2" title="约炮心得" name="2"></van-tab>
				<van-tab key="3" title="服务条款" name="3"></van-tab>
			</van-tabs>

			<div style="color: #fff;">
				<div v-if="active == 0">
					<div class="xuanfei" v-for="(v, k) in datalist" :key="k" @click="profile(v.id)">
						<div class="left-div">
							<div>{{ v.xuanfei_name }}</div>
							<div>
								<ul>
									<!-- <li>昵称: {{ v.xuanfei_name }}</li> -->
									<li>类型: {{ v.xuanfei_type }}</li>
									<li>所在地区: {{ v.catename }}</li>
									<li>服务项目: {{ v.xuanfei_fuwu }}</li>
								</ul>
							</div>
							<div>
								<ul>
									<li>
										<img src="../images/y.png">
										<span>{{ v.xuanfei_view }}</span>
									</li>
									<li>
										<img src="../images/x.png">
										<span>{{ v.xuanfei_dian }}</span>
									</li>
								</ul>
							</div>
						</div>
						<div class="right-div">
							<img :src="v.img_url">
						</div>
						<i class="sign-i">已认证</i>
					</div>
				</div>
				<div v-show="active == 1" class="gd_info1">
					<li v-for="(item,k) in list" ref="rollul" :key="k" :class="{anim:animate==true}"> 
						<div class='gd_name'>{{item.name}}</div>
						<div class=''>{{item.con}}</div>
					</li> 
				</div>
				<div v-show="active == 2" class="gd_info1">
					<li v-for="(item,k) in content2" ref="rollul2" :key="k" :class="{anim:animate2==true}"> 
						<div class='gd_name'>{{item.name}}</div>
						<div class=''>{{item.con}}</div>
					</li> 
				</div>
				<div v-show="active == 3" class="gd_fuwu">
					<ul>
						<li>
							<div class="tit">平台资源</div>
							<div>网红丶模特丶空姐丶嫩模丶大学生，只有您想不到，没有本平台办不到</div>
						</li>
						<li>
							<div class="tit">服务范围</div>
							<div>同城约炮，任意地点空降，国内一二线城市当地均有，三线城市也可以通过上级预约安排。</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			active: 0,
			videoSwiperOption: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				slidesPerGroup: 1,
			},
			notice: this.$t("index.loading"),
			banners: [{}],
			basicData: [],
			gameitem: [{}, {}, {}, {}],
			movielist_0: [{}, {}, {}, {}],
			movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
			isLoading: false,
			movielistSwiperOption: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				slidesPerGroup: 1,
			},
			bannerSwiperOption: {
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: 'auto',
				speed: 2500,
				autoplay: true,
			},
			datalist:[],
			content1:'',
			content2:[],
			animate: true,
			animate2: true,
			list: []
		};
	},
	methods: {
		goscroll() { 
			let con1 = this.$refs.rollul; 
			con1[0].style.marginTop = '48px'; 
			this.animate = !this.animate; 
			var that = this; // 在异步函数中会出现this的偏移问题，此处一定要先保存好this的指向 
			setTimeout(function() { 
				that.list.push(that.list[0]); 
				that.list.shift(); 
				con1[0].style.marginTop = '0px'; 
				that.animate = !that.animate; // 这个地方如果不把animate 取反会出现消息回滚的现象，此时把ul 元素的过渡属性取消掉就可以完美实现无缝滚动的效果了 
			}, 0) 
		},
		goscroll2() { 
			let con1 = this.$refs.rollul2; 
			con1[0].style.marginTop = '48px'; 
			this.animate2 = !this.animate2; 
			var that = this; // 在异步函数中会出现this的偏移问题，此处一定要先保存好this的指向 
			setTimeout(function() { 
				that.content2.push(that.content2[0]); 
				that.content2.shift(); 
				con1[0].style.marginTop = '0px'; 
				that.animate2 = !that.animate2; // 这个地方如果不把animate 取反会出现消息回滚的现象，此时把ul 元素的过渡属性取消掉就可以完美实现无缝滚动的效果了 
			}, 0) 
		},
		getxuanfeilist() {
			this.$http({
				method: 'get',
				url: 'xuanfeilist',
				data: { id: 0 }
			}).then(res => {
				this.datalist = res.data;
				//console.log(this.datalist)
			});
		},
		
		profile(id) {
			this.$router.push({ path: '/profile?id=' + id + '&name=' + this.vod_name + '&adsid=' + this.$route.query.id });
		},
		OnChange() {

			console.log(this.active);
		},
		itemChange() {
			this.active = this.$refs.swiper.swiper.activeIndex
			this.OnChange()
		},
		gotoMenu(router) {
			this.$router.replace(router)
		},
		toLottery(key, id) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' })
			} else {
				this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
			}

		},
		toPlayVideo(id) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' })
			} else {
				this.$router.push({ path: '/PlayVideo?id=' + id })
			}
		},
		onRefresh() {
			setTimeout(() => {
				this.getBasicConfig();
				this.isLoading = false;
				this.$toast("刷新成功");
			}, 500);
		},
		getBasicConfig() {
			this.$http({
				method: 'get',
				url: 'sys_config'
			}).then(res => {
				this.basicData = res.data;
				this.getNotice(this.basicData);//获取公告
				this.getBanner(this.basicData);//获取banner
				this.getGameItem();//获取首页游戏列表
				this.getMovieList_0(this.basicData);//获取首页视频0
				this.getMovieList_1(this.basicData);//获取首页视频1
			})

		},
		getNotice(data) {
			this.notice = data.notice;
		},
		getGameItem() {
			this.$http({
				method: 'get',
				url: 'lottery_hot'
			}).then(res => {
				this.gameitem = res.data
			})
		},
		getMovieList_0(data) {
			this.movielist_0 = data.movielist_0
			//console.log(this.movielist_0);
		},
		getMovieList_1(data) {
			this.movielist_1 = data.movielist_1
		},
		getNoticeShow(nid) {
			this.$http({
				method: 'get',
				url: 'sys_get_notice_show',
				data:{id:nid}
			}).then(res => {
				if(nid==7){
					this.list = res.data;
				}
				else{
					this.content2 = res.data;
				}
				
			})

		},
		getBanner(data) {
			this.banners = data.banners;
		}
	},
	mounted() {
		const timer = setInterval(this.goscroll,2000);
           // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。  
             this.$once('hook:beforeDestroy', () => {     
             clearInterval(timer);})
		const timer2 = setInterval(this.goscroll2,2000);
           // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。  
             this.$once('hook:beforeDestroy', () => {     
             clearInterval(timer2);})
	},
	created() {
		this.getBasicConfig();
		this.getNoticeShow(7);
		this.getNoticeShow(8);
		this.getxuanfeilist();
	}
}

</script>

<style lang='less' scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;

.gd_info1{
	width: 100%;
    height: 220px;
    border-radius: 10px;
    border: 1px solid #766350;
    margin: 10px 0;
    background: linear-gradient(120deg, #212121, #313131);
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    position: relative;
    overflow: hidden;
}

.gd_info1 li{
	//height: 30px; 
	line-height: 24px; 
	font-size: 14px; 
	list-style: none;
	margin-bottom: 20px;
	.gd_name{
		color:#e6b98a;
	}
}
.anim {transition: all 0.5s; } 	

.gd_fuwu{
	width: 100%;
    height: 220px;
    border-radius: 10px;
    border: 1px solid #766350;
    margin: 10px 0;
    background: linear-gradient(120deg, #212121, #313131);
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    position: relative;
    overflow: hidden;
	li{
		margin-bottom: 10px;
		.tit{
			font-size: 18px;
			color: #f3d3b8;
			font-weight: 700;
		}
	}
}

.linear-bg {
	height: 200px;
	//background: linear-gradient(270deg, #e6c3a1, #7e5678);
}

.home-container {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #16151b;
}

.linear-gradient {
	width: 100%;
	height: 2px;
	background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
	min-width: 40px;
}

.xuanfei{
	width: 100%;
    height: 188px;
    border-radius: 10px;
    border: 1px solid #766350;
    margin-bottom: 10px;
    background: linear-gradient(120deg, #212121, #313131);
    display: flex;
    justify-content: space-between;
    padding: 10px;
    position: relative;
    overflow: hidden;
	.left-div{
		width: 220px;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}
	.right-div{
		width: 130px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		img{
			width: 110px;
			height: 110px;
			-o-object-fit: cover;
			object-fit: cover;
			border-radius: 50%;
		}
	}
	.sign-i{
		position: absolute;
		right: -30px;
		top: 7px;
		background: #ff6056;
		font-size: 14px;
		padding: 5px 30px;
		font-style: normal;
		transform: rotate(45deg);
		color: #fff;
	}
}
.xuanfei .left-div>div:first-child{
	background: linear-gradient(90deg, #efcba7, #dcab76);
    color: #8d5825;
    height: 22px;
    padding: 0 10px;
    border-radius: 11px;
    line-height: 22px;
    text-align: center;
    font-size: 14px;
}
.xuanfei .left-div>div:nth-child(2){
	margin: 10px 0;
    padding-left: 10px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.xuanfei .left-div>div:nth-child(3) ul{
	display: flex;
}
.xuanfei .left-div>div:nth-child(3) ul li{
	display: flex;
    align-items: center;
    justify-content: flex-start;
	color:#696969;
	img{
		height:14px;
	}
}
.xuanfei .left-div>div:nth-child(3) ul>li:first-child{
	margin-right: .13333rem;
}

.describe {
	width: 100%;
	min-height: 166px;
	border-radius: 10px;
	border: 1px solid #766350;
	margin: 13px 0;
	background: linear-gradient(120deg, #212121, #313131);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 5px;
	position: relative;
	overflow: hidden;

	ul {
		height: 88px;
		display: flex;
		align-items: flex-start;

		li {
			height: 100%;
		}
	}
}

.describe>div {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #e6b98a;
	line-height: 24px;
	font-size: 12px;
}

.describe>ul>li:first-child {
	width: 146px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	border-right: 2px solid #e6b98a;
}

.describe>ul>li:first-child div {
	color: #f3d3b8;
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
}

.describe>ul>li:first-child>div:first-child {
	font-size: 18px;
	font-weight: 700;
	height: 40px;
	text-align: center;
}

.describe>ul>li:nth-child(2) {
	padding: 0 0 0 10px;
	width: calc(100% - 70px);
	font-size: 12px;
	line-height: 24px;
	color: #fff;
}

.describe:after,
.describe:before {
	content: "";
	position: absolute;
	width: .53333rem;
	height: 6.66667rem;
	background: linear-gradient(90deg, rgba(74, 70, 70, .29), transparent);
	transform: rotate(50deg);
}

.describe:after {
	left: 50%;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 33px;
}

::v-deep .van-tabs__nav {
	background:none;
}

::v-deep .van-tab{
	height: 28px;
    min-width: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 44px;
    background: linear-gradient(90deg, #5b606c, #2b2b2b);
    font-size: 14px;
    padding: 0 15px;
    color: #fff;
    text-align: center;
    white-space: nowrap;
	margin-right: 8px;
}
::v-deep .van-tab--active{
	background: linear-gradient(#fde3ca, #e7b486);
    color: #8a5623;
}
::v-deep .van-tabs__line{
	position: absolute;
    bottom: 15px;
    left: 0;
    z-index: 1;
    width: 40px;
    height: 3px;
    background: none;
    border-radius: 3px;
}

.video_swiper {
	width: 100%;
	flex: 1;

	.swiper-slide {
		flex-shrink: 0;
		flex-grow: 0;
		flex-basis: 100%;
		justify-content: center;
		height: 100%;
		position: relative;
		transition-property: transform;
	}
}

.notice-swipe {
	width: calc(100% - 50px);
	height: 85px;
	font-size: @notice-bar-size;
}

::v-deep .van-icon-bullhorn-o::before {
	transform: scale(2.5);
}

.banner {
	width: 100%;
	height: 170px;
}

.banner_swiper {
	height: 100%;
	width: 100%;

	.swiper-slide {
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-weight: bold;
		font-size: 20px;
		background-color: #ffffff;
		background-position: center;
		background-size: cover;
		color: #ffffff;
	}
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
	background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.banner_img {
	border-radius: 10px;
	width: 100%;
	height: 100%;
}

.hot-game {
	width: 100%;
	height: 100%;
}

.hot-title-div {
	width: calc(100% - 50px);
	margin: 0 auto;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.hot-title-div>div:first-child {
	width: 430px;
}

.hot-title-div div {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.hot-title-div>div:nth-child(2) span {
	font-size: 20px;
	color: #979799;
}

.hot-title-div>div:first-child span {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 10px;
	font-weight: 700;
	color: #000;
}

.hot-title-div>div:first-child span {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 28px;
	font-weight: 700;
	color: #000;
}

.hot-title-div>div:nth-child(2) span {
	font-size: 25px;
	color: #979799;
}

.hot-title-div>div:first-child span:before {
	content: "";
	display: block;
	width: 5px;
	height: 30px;
	background-color: #7e5678;
	border-radius: 1px;
	margin-right: 5px;
}

.hot-game .hot-items-div {
	margin-top: -3px;
}

.hot-game .hot-items-div span {
	margin-top: 10px;
	font-size: 28px;
	color: #000;
}

.hot-recommend {
	width: 100%;
	flex: 1;
	background-color: #f2f2f5;
}

.movie_swiper {
	.swiper-slide {
		width: 80%;
	}
}

.movie_list_0 {
	width: calc(100% - 50px);
	margin: 0 auto;
}

.movie_cover {
	border-radius: 10px;
	width: 550px;
	height: 330px
}

.movie_list_0 .movie-list-item-bottom {
	position: relative;
	width: 550px;
	bottom: 43px;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div {
	background-color: rgba(0, 0, 0, .4);
}

.movie_list_0 .movie-list-item-bottom>div {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
	font-size: 28px;
	color: #fff;
}

.movie_list_0 .movie-time-div {
	color: #fff;
	border-radius: 0 0 10px 10px;
}

.movie_list_0 .movie_swiper .hot {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 80px;
}

.movie_list_0 span {
	font-size: 30px;
}

.movie_list_1 {
	display: flex;
	width: calc(100% - 50px);
	margin: 0 auto;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.movie_list_1 .movie-list-item .cover_img {
	border-radius: 10px;
	width: 335px;
	height: 290px;
}

.home-scroll {
	width: calc(100% - 24px);
	padding-bottom: 110px;
	margin: 0 auto;
}

.movie_list_1 .movie-list-item {
	margin-bottom: -10px;
}

.movie_list_1 .movie-list-item-bottom {
	position: relative;
	width: 335px;
	bottom: 42px;

}

.movie_list_1 .movie-list-item:nth-child(odd) {
	margin-right: 25px;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div {
	background-color: rgba(0, 0, 0, .4);
}

.movie_list_1 .movie-list-item-bottom>div {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
	color: #fff;
}

.movie_list_1 .movie-time-div {
	color: #fff;
	border-radius: 0 0 10px 10px;
	height: 35px;
}

.movie_list_1 .movie_swiper .hot {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
	overflow: hidden;
	white-space: nowrap;
	width: 180px;
	padding-left: 8px;
	font-size: 25px;
}

.movie_list_1 .movie-list-item .movie-time-div span:last-child {
	overflow: hidden;
	white-space: nowrap;
	width: 0px;
	padding-right: 110px;
	font-size: 22px;
}

.movie_list_0 .movie-time-div span:first-child {
	overflow: hidden;
	white-space: nowrap;
	width: 350px;
	padding-left: 10px;
	font-size: 25px;
}

.hot-recommend-more {
	width: 100%;
	padding-bottom: 20px;
	text-align: center;
	color: #979799;
	font-size: 30px;
}

.hot-items-div .game_item_img {
	width: 100px;
	height: 100px;
}

.movie-list-tab {
	color: #fff;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
	border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #000000;
	font-size: 14px;
}

::v-deep .van-nav-bar {
	height: 44px;
	background: #16151b;
}

::v-deep .van-nav-bar__content {
	height: 44px;
}

::v-deep .van-nav-bar__title {
	height: 44px;
	line-height: 44px;
	font-size: 18px;
	font-weight: 700;
	color:#f3d3b8;
}
</style>
