<template>
	<div class="container page">
		<van-nav-bar :title="$t('setting.setting')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="items">
			<div class="item van-hairline--bottom" @click="toInfomation()">
				<div class="left">{{ $t("setting.base_setting") }}</div>
				<van-icon name="arrow" />
			</div>
			<div class="item van-hairline--bottom" @click="toLoginPassword()">
				<div class="left">{{ $t("setting.login_pwd") }}</div>
				<van-icon name="arrow" />
			</div>
			<div class="item van-hairline--bottom" @click="toPayPassword()">
				<div class="left">{{ $t("setting.finance_pwd") }}</div>
				<div class="right">
					<span class="desc">{{ this.userInfo.paypassword }}</span>
					<van-icon name="arrow" />
				</div>
			</div>
			<!-- <div class="item van-hairline--bottom" @click="toLanguage()">
          <div class="left">{{$t("setting.language")}}</div>
          <div class="right">
            <van-icon name="arrow" />
          </div>
        </div> -->
		</div>
		<van-button class="sign-out" type="primary" size="normal"
			@click="loginout()">{{ $t("setting.logout") }}</van-button>
	</div>
</template>

<script>
export default {
	data() {
		return {
			userInfo: {}
		};
	},
	methods: {
		back() {
			return window.history.back();
		},
		toPayPassword() {
			if (this.userInfo.paypassword !== this.$t("setting.no_setting")) {
				this.$toast(this.$t("withdraw.with_service"));
			} else {
				this.$router.push({ path: '/SetPayPassword' });
			}
		},
		toLoginPassword() {
			this.$router.push({ path: '/SetLoginPassword' });
		},

		toLanguage() {
			this.$router.push({
				name: 'Language',
				params: {
					"type": "setting"
				}
			});
		},
		toInfomation() {
			this.$router.push({ path: '/Infomation' });
		},
		loginout() {
			localStorage.clear()
			this.$router.push({ path: '/Mine' });
		},
		toServicePage() {
			this.$router.push("ServicePage");
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'user_info'
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
					if (res.data.paypassword) {
						this.userInfo.paypassword = this.$t("setting.y_setting");
					} else {
						this.userInfo.paypassword = this.$t("setting.no_setting");
					}
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			})
		}
	},
	created() {
		if (!localStorage.getItem('token')) {
			this.$router.push({ path: '/Login' })
		} else {
			this.getUserInfo();
		}
	}
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

::v-deep .van-nav-bar {
	height: 50px;
	background: none;
	color: #e6b98a;
}

::v-deep .van-nav-bar__title {
	color: #e6b98a;
}

::v-deep .van-hairline--bottom::after {
	border: none;
}

.container .items {
	background-color: #373536;
	font-size: 14px;
	color: #fff;
	padding: 0 15px;
}

.container .items .item {
	padding: 15px 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom:solid 1px #ccc;
}

.container .items .van-hairline--bottom::after {
	border-bottom-width: 1px;
}

.container .sign-out {
	margin: 50px 20px 0;
	height: 50px;
	line-height: 50px;
	border-radius: 6px;
	color: #885b3a;
	font-size: 18px;
	font-weight: bolder;
	border: none;
	background: linear-gradient(#fde3ca, #e7b486);
}

.container .item .desc {
	font-size: 16px;
	font-weight: 700;
	color: #fff;
}

.container .item .right {
	display: flex;
	flex-direction: row;
	align-items: center;
}
</style>
