<template>
	<div class="mine page">
		<div class="wrapper">
			<van-pull-refresh v-model="isLoading" @refresh="onRefresh">
				<div class="header">
					<van-nav-bar class="nav-bar">
					</van-nav-bar>
					<div class="user-wrapper" @click="doLogin()">
						<van-image round class="user_img" :src="this.$store.getters.getBaseInfo.ico">
							<template v-slot:loading>
								<van-loading type="spinner" />
							</template>
						</van-image>
						<div class="login-content">
							<p class="login-btn">
								{{ this.userInfo.username }}
								<img src="../images/vip.png" />
							</p>
							<p class="login-label">{{ $t("my.balance") }}：{{ this.userInfo.money }}</p>
							<p class="login-label">{{ $t("my.videonum") }}：{{ this.userInfo.video_num }}</p>
							<p class="login-label">{{ $t("my.1") }}：{{ this.userInfo.username1 }}</p>

						</div>
						<van-icon class="font-gray" style="font-size: 28px" name="arrow" />
					</div>
				</div>
				<div class="content">
					
					<div class="wallet">
						<div class="part-1" @click="$router.push({ path: '/Accountbag' });">
							<img src="/img/mine/u1.png" style="width:22px;height:22px;margin-right: 10px;" />
							<p class="flex-1 font-14 font-primary-color">{{ $t("my.my_balance") }}</p>
							<van-icon class="font-gray" style="font-size: 28px" name="arrow" />
						</div>
					</div>	

					<div class="wallet">
						<div class="part-1 van-hairline--bottom" @click="$router.push({ path: '/GameRecord' });">
							<img src="/img/mine/u2.png" style="width:22px;height:22px;margin-right: 10px;" />
							<p class="flex-1 font-14 font-primary-color">{{ $t("my.task_record") }}</p>
							<van-icon class="font-gray" style="font-size: 28px" name="arrow" />
						</div>
						<!-- <div class="part-1 van-hairline--bottom" @click="$router.push({ path: '/RechargeRecord' });">
							<img src="/img/mine/u3.png" style="width:22px;height:22px;margin-right: 10px;" />
							<p class="flex-1 font-14 font-primary-color">{{ $t("my.recharge_record") }}</p>
							<van-icon class="font-gray" style="font-size: 28px" name="arrow" />
						</div> -->
						<div class="part-1" @click="$router.push({ path: '/Withdraw' });">
							<img src="/img/mine/u4.png" style="width:22px;height:22px;margin-right: 10px;" />
							<p class="flex-1 font-14 font-primary-color">{{ $t("my.withdraw") }}</p>
							<van-icon class="font-gray" style="font-size: 28px" name="arrow" />
						</div>
					</div>
					
					<div class="wallet">
						<div class="part-1 van-hairline--bottom" @click="$router.push({ path: '/WithdrawRecord' });">
							<img src="/img/mine/u3.png" style="width:22px;height:22px;margin-right: 10px;" />
							<p class="flex-1 font-14 font-primary-color">{{ $t("my.withdraw_record") }}</p>
							<van-icon class="font-gray" style="font-size: 28px" name="arrow" />
						</div>
						<!-- <div class="part-1 van-hairline--bottom" @click="$router.push({ path: '/Personalreport' });">
							<img src="/img/mine/u5.png" style="width:22px;height:22px;margin-right: 10px;" />
							<p class="flex-1 font-14 font-primary-color">{{ $t("my.my_statement") }}</p>
							<van-icon class="font-gray" style="font-size: 28px" name="arrow" />
						</div> -->
						<div class="part-1 van-hairline--bottom" @click="$router.push({ path: '/BindCard' });">
							<img src="/img/mine/u5.png" style="width:22px;height:22px;margin-right: 10px;" />
							<p class="flex-1 font-14 font-primary-color">{{ $t("my.bank") }}</p>
							<van-icon class="font-gray" style="font-size: 28px" name="arrow" />
						</div>
						<div class="part-1" @click="$router.push({ path: '/Setting' });">
							<img src="/img/mine/u9.png" style="width:22px;height:22px;margin-right: 10px;" />
							<p class="flex-1 font-14 font-primary-color">{{ $t("my.setting") }}</p>
							<van-icon class="font-gray" style="font-size: 28px" name="arrow" />
						</div>
					</div>	

				</div>
			</van-pull-refresh>
		</div>
	</div>

</template>

<script>
export default {
	data() {
		return {
			userInfo: {
			},
			menu_top: 40,
			isLoading: false,
		};
	},
	methods: {
		refresh() {
			this.isLoading = true;
			setTimeout(() => {
				this.isLoading = false;
				if (localStorage.getItem('token')) {
					this.$toast(this.$t("reservation.refresh"));
				} else {
					this.$router.push({ path: '/Login' })
				}
			}, 500);
		},
		exit() {
			this.$toast(this.$t("my.finish_task"));
		},
		showSetting() {
			if (localStorage.getItem('token')) {
				this.$router.push({ path: '/Setting' })
			} else {
				this.$router.push({ path: '/Login' })
			}
		},
		toNotice() {
			if (localStorage.getItem('token')) {
				this.$router.push({ path: '/Notice' })
			} else {
				this.$router.push({ path: '/Login' })
			}
		},
		onRefresh() {
			setTimeout(() => {
				this.isLoading = false;
				if (localStorage.getItem('token')) {
					this.getUserInfo();
					this.$toast(this.$t("reservation.refresh"));
				} else {
					this.$router.push({ path: '/Login' })
				}
			}, 500);
		},
		doLogin() {
			if (localStorage.getItem('token')) {
				this.$router.push({ path: '/Infomation' });
			} else {
				this.$router.push({ path: '/Login' })
			}
		},
		doPay() {
			this.$router.push({
				name: 'Recharge',
				params: {
					'balance': this.userInfo.money
				}
			})
		},
		doWithdrawal() {
			this.$http({
				method: 'get',
				url: 'user_get_bank'
			}).then(res => {
				if (res.data.is_bank) {
					this.$router.push("withdraw");
				} else {
					this.$router.push("Setbank");
					this.$toast.fail(this.$t("setting.set_bank"));
				}
			})
		},
		toService() {
			if (this.$store.getters.getBaseInfo.iskefu == 1) {
				this.$router.push("ServiceOnline");
			} else {
				this.$toast.fail(this.$t("setting.forbid"));
			}
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'user_info'
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
					this.menu_top = 15;
					if (this.userInfo.status !== 1) {
						this.$toast(this.$t("video.account_out"));
						localStorage.clear()
						this.$router.push({ path: '/Login' })
					}
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			})
		},
	},
	created() {
		if (localStorage.getItem('token')) {
			this.getUserInfo();
		} else {
			this.userInfo.username = this.$t("setting.log_reg");
			this.userInfo.ip = this.$t("setting.more_service");
			this.userInfo.header_img = "img/mine/avatar.png";
		}
	}
};
</script>

<style scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;

}

.mine {
	position: relative;
	bottom: 10px;

}

.mine .wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.nav-bar {}

.mine .header {
	background: #212121;
	margin-bottom: 10px;
}

::v-deep .van-nav-bar {
	height: 40px;
	background: none;
}

::v-deep .van-nav-bar__content {
	height: 40px;
}

::v-deep .van-hairline--bottom::after {
	border-bottom-width: 0px;
}

.mine .header .van-nav-bar .van-icon {
	font-size: 45px;
}

.mine .header .user-wrapper {
	display: flex;
	align-items: center;
	margin: 0px 15px;

}

.mine .user_img {
	height: 50px;
	width: 50px;
	border: .05333rem solid #fff;
	border-radius: 25px;
}

::v-deep .van-loading__spinner {
	height: 50px;
	width: 50px;
}

::v-deep .van-image__error-icon {
	font-size: 70px;
}

.mine .header .user-wrapper .login-content {
	flex: 1;
	margin-left: 30px;
}

.mine .header .user-wrapper .login-content .login-btn {
	display: inline-block;
	font-size: 14px;
	color: #fff;
}

.mine .header .user-wrapper .login-content .login-btn img{
	margin-left: 10px;
    width: 44px;
    height: 22px;
    -o-object-fit: contain;
    object-fit: contain;
    float: right;
    margin-top: -5px;
}

.mine .header .user-wrapper .login-content .login-label {
	margin-top: -13px;
	font-size: 14px;
	color: hsla(0, 0%, 100%, .6);
}

.mine .page-bg {
	height: 500px;
	background: linear-gradient(90deg, #7e5678, #e6c3a1);
}

.mine .content {
	position: relative;
	min-height: 500px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #ffffff;
	font-size: 35px;
}

.mine .wrapper .content .finance {
	position: absolute;
	display: flex;
	align-items: center;
	top: -55px;
	left: 30px;
	right: 30px;
	height: 120px;
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .finance .line {
	width: 3px;
	height: 40px;
	background-color: #ccc;
}

.mine .wrapper .content .finance .finance-item {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.mine .wrapper .content .finance .finance-item .text {
	margin-left: 30px;
	font-size: 30px;
	color: #000;
	font-weight: 500;
}

.mine .wrapper .content .finance .finance-item .icon {
	font-size: 50px;
}

.mine .wrapper .content .menu {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .menu .menu-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 50%;
	height: 130px;
}

.mine .wrapper .content .menu .menu-item .menu-item-label {
	font-size: 30px;
	color: #868686;
	font-weight: 500;
}

.mine .wrapper .content .menu .menu-item .menu-item-icon {
	margin: 25px;
	width: 60px;
	height: 60px;
	-o-object-fit: contain;
	object-fit: contain;
}

.mine .wrapper .content .wallet {
	
	padding: 0 10px;
	background-color: #212121;
	margin-bottom: 10px;
	
}

.mine .wrapper .content .wallet .part-1 {
	display: flex;
	align-items: center;
	height: 50px;
}

.mine .wrapper .content .wallet .font-primary-color {
	color: #fff;
}

.font-gray {
	color: #868686;
}

.mine .wrapper .content .wallet .part-2 {
	display: flex;
	align-items: center;
	height: 150px;
}

.mine .wrapper .content .wallet .part-2 .balance {
	flex: 1;
	font-size: 60px;
	color: #7e5678;
	font-weight: 700;
}

.mine .wrapper .content .wallet .van-hairline--bottom::after {
	border-bottom:solid 1px hsla(0,0%,80%,.3);
}

.mine .wrapper .content .wallet .part-2 .refresh-btn {
	margin-left: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	font-size: 30px;
	border-radius: 50%;
	color: #ffffff;
	background-color: #e6c3a1;
}
</style>
