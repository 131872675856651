<template>
	<div class="page">
		<van-nav-bar class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="banner">
			<swiper class="banner_swiper" :options="bannerSwiperOption">
				<swiper-slide v-for="(v, key) in xuanfeidata.img_url" :key="key">
					<van-image class="banner_img" round :src="v">
						<template v-slot:loading>
							<van-loading type="circular" />
						</template>
					</van-image>
				</swiper-slide>
			</swiper>
		</div>
		<div class="notice-bar">
			<van-notice-bar
				class="notice-swipe"
				left-icon="bullhorn-o"
				color="#e6b98a"
				:text="this.notice"
			/>
			<div class="linear-gradient"></div>
		</div>
		<div class="box">
			<div class="xf_info">
				<p class="name">{{ this.xuanfeidata.xuanfei_name }}</p>
				<p class="title">{{ this.xuanfeidata.xuanfei_type }}</p>
			</div>
			<div class="xf_pingfen">
				<div>
					<p>评分</p>
					<p>{{ this.xuanfeidata.xuanfei_fen }}</p>
					<p>美女颜值</p>
				</div>
				<div>
					<p>详细地址：{{ this.xuanfeidata.xuanfei_address }}</p>
					<p>联系方式：{{ this.xuanfeidata.xuanfei_tel }}</p>
				</div>
			</div>
			<div class="xf_fuwu">
				<div>
					<img src="../images/1.png" />
					{{ this.xuanfeidata.xuanfei_info }}
				</div>
				<div>
					<img src="../images/2.png" />
					{{ this.xuanfeidata.xuanfei_address }}
				</div>
				<div>
					<img src="../images/3.png" />
					{{ this.xuanfeidata.xuanfei_fuwu }}
				</div>
				<div class="sign">{{ this.xuanfeidata.xuanfei_sign }}</div>
			</div>
			<van-image width="98%" fit="contain" height="100%" v-for="(v, k) in xuanfeidata.img_url" :key="k" :src="v" />
			<van-button round="true" @click="yuyue" class="button">{{$t("foorter.subscribe")}}</van-button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			xuanfeidata: [],
			bannerSwiperOption: {
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: 'auto',
				speed: 2500,
				autoplay: true,
			},
			notice:this.$t("index.loading"),
		};
	},
	methods: {
		back() {
			this.$router.push({ path: 'Home' });
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata = res.data;
			});
		},
		getBasicConfig() {
			this.$http({
				method: 'get',
				url: 'sys_config'
			}).then(res => {
				this.notice = res.data.notice;
			})

		},
		yuyue() {
			this.$toast(this.$t("reservation.counselor"));
		}
	},
	created() {
		this.getBasicConfig();
		this.getxuanfeidata();
	}
};
</script>

<style lang='less' scoped>
.container {
	display: inline-block;
}

.banner {
	width: 100%;
	height: 538px;
}

.banner_swiper {
	height: 100%;
	width: 100%;

	.swiper-slide {
		/* border-radius: 10px; */
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-weight: bold;
		font-size: 20px;
		background-color: #ffffff;
		background-position: center;
		background-size: cover;
		color: #ffffff;
	}
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
	background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.banner_img {
	border-radius: 10px;
	width: 100%;
	height: 100%;
}

.notice-bar{
	padding: 0 10px;
    background-color: transparent;
    border: 1px solid #e6b98a;
    border-radius: 4px;
    height: 33px;
    margin: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
	min-width: 40px;
}
::v-deep .van-notice-bar{
	background: none;
}

.notice-swipe {
	width: 100%;
	
	font-size: 14px;
}


.box {
	width: 95%;
	margin: 0 auto;
	text-align: center;
	padding-bottom: 6.25rem;
	color:#fff;
}
.xf_info{
	text-align: left;
	font-size: 16px;
	line-height: 28px;
	p{
		margin: 0;
	}
}
.xf_pingfen{
	border: 1px solid #7e6a56;
    height: 88px;
    margin-bottom: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.xf_pingfen>div{
	height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.xf_pingfen>div:first-child{
	width:30%;
	p{
		margin: 0;
	}
}
.xf_pingfen>div:first-child>p:nth-child(2){
	color: #f3d3b8;
    font-size: 18px;
    font-weight: 700;
}
.xf_pingfen>div:nth-child(2){
	width:70%;
	align-items: start;
	padding-left: 20px;
	p{
		margin: 0;
		font-size: 14px;
	}
}
.xf_fuwu{
	border: 1px solid #7e6a56;
    
    margin-bottom: 10px;
    border-radius: 4px;
	padding:20px;
}
.xf_fuwu div{
	margin: 0;
	display: flex;
	align-items: start;
	justify-content: flex-start;
	height: 30px;
	img{
		width:20px;
		height:20px;
		margin-right: 5px;
	}
}
.xf_fuwu .sign{
	border-top: 2px solid #7e6a56;
	line-height: 30px;
}

.button {
	width: 70%;
    height: 40px;
    background: linear-gradient(#fde3ca, #e7b486);
    text-align: center;
    margin: 20px auto;
    line-height: .96rem;
    color: #333;
    border-radius: 15px;
    font-size: 16px;
}
.nav-bar{
	height: 50px;
}
.van-nav-bar{
	height: 50px;
	position: fixed;
	top: 0;
	z-index: 999;
}

</style>
