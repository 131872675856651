<template>
	<div class="bg-container page">

		<div class="bg-wrapper">
			<div class="login">
				<van-nav-bar class="nav-bar">
					<template #left>
						<van-icon name="arrow-left" color="#fff" @click="back()" />
					</template>
				</van-nav-bar>
				<div class="wrapper">
					<div class="title">{{ $t("my.welcome") }}{{ this.$store.getters.getBaseInfo.name !== undefined
							? this.$store.getters.getBaseInfo.name : this.$t("my.title") }}</div>
					<div class="loginForm">
						<van-field v-model="username" clearable input-align="center" class="input"
							:placeholder="$t('auth.username_place')" />
						<van-field v-model="password" type="password" input-align="center" class="input"
							:placeholder="$t('auth.pwd_place')">
						</van-field>
						<van-button class="login-btn" type="primary" size="normal"
							@click="doLogin()">{{ $t("auth.login") }}</van-button>
						<div @click="toRegister()" class="register-text">
							{{ $t("auth.no_account") }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	model: {
		prop: "inputValue",
		event: "input",
	},
	props: {
		/**
		 * 当前输入的值
		 */
		inputValue: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			username: "",
			lang: 'en_us',
			password: this.inputValue,
			passwordType: "password",
		};
	},
	mounted() {
		// localStorage.setItem("lang", 'ms_my');
		this.lang = localStorage.getItem("lang") || 'ja_jp';
	},
	methods: {
		switchPasswordType() {
			this.passwordType =
				this.passwordType === "password" ? "text" : "password";
		},
		back() {
			return window.history.back();
		},
		toRegister() {
			this.$router.push("Register");
		},
		doLogin() {
			if (
				this.username === "" ||
				this.username === null ||
				this.username === undefined
			) {
				this.$toast(this.$t("auth.username_place"));
				return false;
			}
			if (
				this.password === "" ||
				this.password === null ||
				this.password === undefined
			) {
				this.$toast(this.$t("auth.pwd_place"));
				return false;
			}
			this.$http({
				url: "member_login",
				method: "post",
				data: {
					username: this.username,
					password: this.password,
					lang: this.lang
				},
			}).then((res) => {
				if (res.code === 200) {
					this.$toast.success(res.msg);
					localStorage.setItem("token", res.data.id);
					this.$router.push("Home");
				} else {
					this.$toast(res.msg);
				}
			});
		},
	},
	created() {
		if (localStorage.getItem("token")) {
			return window.history.back();
		}
	},
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.login {
	height: 100%;
}
.bg-container{
	background:#282828;
	.bg-wrapper{
		background:none;
	}
}
.bg-container .bg-wrapper .login .nav-bar {
	background: 0 0;
}

.language {
	position: absolute;
	top: 4px;
	right: 0;
	height: 80px;

	img {
		height: 100%;
	}
}

.login .wrapper {
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.logo-container {
	margin: 0 auto;
	width: 45%;
}

.logo-container .logo-wrapper {
	position: relative;
	padding-bottom: 62.5%;
}

.logo-container .logo-wrapper .logo-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-o-object-fit: contain;
	object-fit: contain;
}

.login .wrapper .title {
	line-height: 78px;
	text-align: center;
	font-size: 18px;
	font-weight: 700;
	color: #fff;
	letter-spacing: 5px;
}

.login .wrapper .loginForm {
	padding: 0 30px;
}

.login .wrapper .loginForm .input {
	padding: 10px 20px;
    margin-top: 20px;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    color: #fff;
    background-color: #fff3;
}

::v-deep .van-field__right-icon .van-icon {
	font-size: 50px;
}

::v-deep .van-icon {
	font-size: 20px;
}
::v-deep .van-cell::after {
	border: none;
}
::v-deep .van-field__control {
	color:#fff;
}

.login .wrapper .loginForm .reset-text {
	margin: 30px 15px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.login .wrapper .loginForm .reset-text span {
	color: #fff;
	font-size: 25px;
	font-weight: 500;
	line-height: 15px;
}

.login .wrapper .loginForm .register-text {
	margin: 10px 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.login .wrapper .loginForm .register-text span {
	color: #fff;
	font-size: 25px;
	font-weight: 500;
	line-height: 20px;
}


.login .wrapper .loginForm .login-btn {
	margin-top: 30px;
    width: 100%;
    height: 50px;
    color: #fff;
    background: linear-gradient(#fde3ca, #e7b486);
    font-size: 18px;
    font-weight: bolder;
    border: none;
}
.login .wrapper .loginForm .register-text {
	margin-top: 55px;
    width: 100%;
    height: 50px;
    color: #fff;
    background: linear-gradient(#716c68, #cdcac7);
    font-size: 18px;
    font-weight: bolder;
    border: none;
}
</style>
