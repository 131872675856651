<template>
	<div class="container page">
		<van-nav-bar :title="$t('my.task_record')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="main">
			<div class="content_head">
				<div class="content_head_top">
					<div class="van-image content_head_top_logo" style="width: 124px; height: 70px;"><img :src="list.ico"
							class="van-image__img" style="object-fit: cover;"><!----><!----></div>
					<div class="content_head_top_name">{{ list.title }}</div>
					<div class="content_head_top_period">第{{list.expect}}期</div>
				</div>
				<div class="content_head_bottom">
					<div class="content_head_bottom_wrapper"><span
							class="content_head_bottom_wrapper_status_text font_grey">{{ list.money }}</span><span
							class="content_head_bottom_wrapper_text">认证积分</span></div>
					<div class="content_head_bottom_line"></div>
					<div class="content_head_bottom_wrapper"><span
							class="content_head_bottom_wrapper_status_text font_red">{{ list.profit }}</span><span
							class="content_head_bottom_wrapper_status_text font_red">已获积分</span>
					</div>
				</div>
			</div>
			<div class="division"></div>
			<div class="content_wrapper">
				<div class="content_wrapper_head">
					<div class="content_wrapper_head_text">认证内容</div>
				</div>
				<div class="content_wrapper_cell">
					<div class="content_wrapper_cell_label">开奖号码:</div>
					<div class="content_wrapper_cell_content"><!---->
						<div class="kuaisan-ball left" isactiveclick="true" playtypecode="sumValueTwoSides" period="202410140385" playname="两面" >
							<span class="res-des middle" >{{ list.re1 }}</span>
							<span class="res-des middle" >{{ list.re2 }}</span>
						</div>
					</div>
				</div>
				<!-- <div class="content_wrapper_cell">
					<div class="content_wrapper_cell_label">订单号码:</div>
					<div class="content_wrapper_cell_content">
						<div class="content_wrapper_cell_content_normal">{{ list.profit }}</div>
					</div>
				</div> -->
				<div class="content_wrapper_cell">
					<div class="content_wrapper_cell_label">投注号码:</div>
					<div class="content_wrapper_cell_content">
						<div class="content_wrapper_cell_content_normal">{{ list.type }}</div>
					</div>
				</div>
				<div class="content_wrapper_cell">
					<div class="content_wrapper_cell_label">投注时间:</div>
					<div class="content_wrapper_cell_content">
						<div class="content_wrapper_cell_content_normal">{{ list.create_time }}</div>
					</div>
				</div><!---->
			</div>
			<!-- <div class="footer">
				<div class="footer_wrapper">
					<div class="footer_wrapper_text">再来一单</div>
				</div>
			</div> -->
		</div>

	</div>
</template>

<script>
export default {
	data() {
		return {
			isLoading: false,
			list: [],
			id:0
		};
	},
	methods: {
		back() {
			return window.history.back();
		},
		onRefresh() {
			setTimeout(() => {
				this.$toast(this.$t("reservation.refresh"));
				this.isLoading = false;
			}, 500);
		},
		getUserGameList() {
			this.$http({
				method: 'get',
				data:{expect:this.$route.query.expect},
				url: 'user_get_game_show'
			}).then(res => {
				if (res.code === 200) {
					console.log(res.data)
					this.list = res.data;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			})
		}

	},
	created() {
		if (!localStorage.getItem('token')) {
			this.$router.push({ path: '/Login' })
		} else {
			this.getUserGameList();
		}
	}
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #000000;
	font-size: 35px;
}

::v-deep .van-loading__text {
	color: #000000;
	font-size: 35px;
}

::v-deep .van-nav-bar {
	height: 50px;
	background: none;
	color: #e6b98a;
}

::v-deep .van-nav-bar__title {
	color: #e6b98a;

}

::v-deep .van-hairline--bottom::after {
	border: none;
}

::v-deep .van-icon {
	font-size: 16px;
	color: #e6b98a;
}

.container .main {
	position: relative;
	overflow: auto;
	height: 100%;
}


.container {
    display: flex;
    flex-direction: column;
    height: 100%
}

.container .nav {
    background: linear-gradient(270deg,transparent,transparent)
}

.container .nav .van-nav-bar__text {
    color: #fff
}

.container .main {
    background-color: #282828;
    flex: 1
}

.container .main .content_head {
    padding: 0 10px
}

.container .main .content_head .content_head_bottom {
    display: flex;
    flex-direction: row;
    height: 110px;
    align-items: center
}

.container .main .content_head .content_head_bottom .content_head_bottom_line {
    width: .016rem;
    height: 40%;
    background-color: #f2f2f5
}

.container .main .content_head .content_head_bottom .content_head_bottom_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1
}

.container .main .content_head .content_head_bottom .content_head_bottom_wrapper .content_head_bottom_wrapper_status_text {
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    text-align: center
}

.container .main .content_head .content_head_bottom .content_head_bottom_wrapper .content_head_bottom_wrapper_text {
    color: #979799;
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px
}

.container .main .content_head .content_head_top {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #f2f2f5;
    padding: 20px 0
}

.container .main .content_head .content_head_top .content_head_top_logo {
    width: 1.33333rem;
    height: 1.33333rem
}

.container .main .content_head .content_head_top .content_head_top_name {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0
}

.container .main .content_head .content_head_top .content_head_top_period {
    color: #979799;
    font-size: 13px;
}

.container .main .division {
    height: .13333rem;
    background-color: #f2f2f5
}

.container .main .content_wrapper {
    padding: 0 10px
}

.container .main .content_wrapper .content_wrapper_head {
    height: 55px;
    display: flex;
    justify-items: center;
    align-items: center;
    color: #fff
}

.container .main .content_wrapper .content_wrapper_head .content_wrapper_head_text {
    font-size: 16px;
    font-weight: 700
}

.container .main .content_wrapper .content_wrapper_cell {
    display: flex;
    flex-direction: row;
    padding: 10px 0
}

.container .main .content_wrapper .content_wrapper_cell .content_wrapper_cell_content {
    display: flex;
    flex: 1;
    padding: 0 15px;
    color: #fff
}

.container .main .content_wrapper .content_wrapper_cell .content_wrapper_cell_content .content_wrapper_cell_content_normal {
    font-size: 14px;
    color: #fff
}

.container .main .content_wrapper .content_wrapper_cell .content_wrapper_cell_label {
    color: #fff;
    font-size: 16px
}

.container .main .content_wrapper .content_wrapper_cell .shopMall-result-div span {
    font-size: 16px;
    margin-right: 10px;
    color: #fff
}

.container .main .footer {
    background-color: #282828
}

.container .main .footer .footer_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: .61333rem;
    height: 1.17333rem;
    margin: .4rem;
    overflow: hidden;
    background: linear-gradient(270deg,#e6b98a,#e6b98a)
}

.container .main .footer .footer_wrapper .footer_wrapper_text {
    color: #282828;
    font-size: .42667rem
}

.container .font_black {
    color: #000
}

.container .font_grey {
    color: #979799
}

.container .font_red {
    color: #dc2037
}
.kuaisan-ball{
	flex: 1;
    display: flex;
    align-items: center;
	justify-content: flex-start;
}
.kuaisan-ball .res-des{
	font-weight: 700;
    text-align: center;
    color: #fff;
	width: 20%;
    font-size: 16px;
}
</style>
