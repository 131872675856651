<template>
	<div class="movie-hall page">
		<van-nav-bar class="nav-bar" :title="$t('foorter.live')" />
		<div class="hot-recommend-div">
			<div class="live-item" v-for="(v, key) in videolist" :key="key">
				<div>
					<div class="avatar-div">
						<img class="avatar-img" :src="v.img_url" />
						<img class="live-img" src="../images/live1.gif" />
					</div>
					<ul>
						<li>{{ v.title }}</li>
						<li>官方认证女神</li>
						<li>已缴纳20000保证金</li>
						<li>所在地区:{{ v.address }}</li>
					</ul>
				</div>

				<div>
					<div class="video-img-div" @click="toPlayVideo(v.id)"><img :src="v.img_url" /><span class="play"><b></b></span>
					</div>
					<ul>
						<li>签名:{{ v.xuanfei_sign }}</li>
						<li>
							<div v-for="(v2, key2) in v.sign" :key="key2">{{ v2 }}</div>
						</li>
						<li @click="tipshow = true"><img src="../images/live3.png" /><span>获取联系方式</span></li>
					</ul>
				</div>
			</div>
		</div>
		
		<div v-show="tipshow" class="van-overlay" style="z-index: 15; animation-duration: 0.2s;"></div>
		<div v-show="tipshow" class="server-dialog">
			<ul>
				<li>温馨提示</li>
				<li>联系数据老师激活权限</li>
				<li @click="tipshow = false">确定</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { Toast } from 'vant';
export default {
	data() {
		return {
			active: 0,
			isLoading: false,
			count: 0,
			loading: false,
			finished: false,
			refreshing: false,
			videolitem: [],
			videolist: [],
			number: 0,
			page: 0,
			videoSwiperOption: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				slidesPerGroup: 1,
			},
			tipshow:false
		};
	},
	methods: {
		getVideoClass() {
			this.$http({
				method: 'get',
				url: 'livelist'
			}).then(res => {
				this.videolitem = res.data;
				console.log(this.videolitem);
			})
		},
		toPlayVideo(id) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' })
			} else {
				this.$router.push({ path: '/LiveVideo?id=' + id })
			}

		},
		itemChange() {
			this.active = this.$refs.swiper.swiper.activeIndex
			this.OnChange()
		},
		getVideoList() {
			this.$http({
				method: 'get',
				data: { id: this.active, page: this.page },
				url: 'livelist'
			}).then(res => {
				this.videolist = this.videolist.concat(res.data);
				console.log(this.videolist);
				this.count = res.data.count;
				this.page++;

			})
		},
		onLoad() {
			this.getVideoList();
			let timer = setTimeout(() => {
				if (this.refreshing) {
					this.videolist = [];
					this.refreshing = false;
				}
				this.loading = false;
				if (this.videolist.length === this.count) {
					this.finished = true;
				}
				this.finished && clearTimeout(timer);//清除计时器
			}, 500);
		},
		onRefresh() {
			setTimeout(() => {
				this.finished = false;
				this.loading = true;
				this.onLoad();
				this.isLoading = false;
				Toast(this.$t("reservation.refresh"));
			}, 500);
		},
	},
	created() {
		this.getVideoList();//获取视频类目
	}
};
</script>

<style lang='less' scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #212121;
}

.nav-bar {
	background: none;
	height: 50px;
}

.van-nav-bar {
	line-height: 50px;
}

::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #e6b98a;
	font-size: 18px;
}

::v-deep .van-nav-bar__content {
	height: 50px;
}

::v-deep .van-tab__text--ellipsis {
	overflow: unset;
}

.movie-hall {
	display: flex;
	flex-direction: column;
	background: #212121;
}

::v-deep .van-tabs__nav {
	background: linear-gradient(to right, rgb(126, 86, 120), rgb(230, 195, 161));
}

::v-deep .van-tab {
	color: #ffffff;
	font-size: 30px;
}

::v-deep .van-tabs__line {
	bottom: 15px;
	width: 55px;
	height: 7px;
	border-radius: 0px;
	background-color: #ffffff;
}

::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}

::v-deep .van-hairline--bottom::after {
	border-bottom-width: 0px;
}

.video_swiper {
	width: 100%;
	flex: 1;

	.swiper-slide {
		flex-shrink: 0;
		flex-grow: 0;
		flex-basis: 100%;
		justify-content: center;
		height: 100%;
		position: relative;
		transition-property: transform;
	}
}

.movie-list-tab {
	overflow: auto;
	height: 100%;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #000;
	font-size: 35px;
}

.hot-recommend-div {
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: 0 10px;
	//overflow: auto;
}

.list-item {
	display: flex;
	width: calc(100% - 50px);
	margin: 10px auto;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.live-item{
	width: 100%;
    background: #313131;
    border-radius: 10px;
    margin-bottom: 10px;
}
.live-item>div{
	width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.live-item>div>div{
	display: flex;
    align-items: center;
    justify-content: center;
}
.live-item>div:first-child{
	height: 100px;
    margin-bottom: 10px;
}
.live-item>div:first-child .avatar-div{
	width: 100px;
    margin: 0 20px;
    position: relative;
}
.live-item>div:first-child .avatar-div .avatar-img{
	width: 84px;
    height: 84px;
    object-fit: cover;
    border-radius: 50%;
}
.live-item>div:first-child .avatar-div .live-img{
	width: 22px;
    position: absolute;
    right: -5px;
    top: calc(50% + 10px);
}
.live-item>div>ul{
	display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.live-item>div:first-child>ul{
	width: 250px;
}
.live-item>div>ul>li{
	display: flex;
    align-items: center;
    justify-content: flex-start;
}
.live-item>div:first-child>ul>li{
	font-size: 16px;
    font-weight: 700;
    color: #fff;
}
.live-item>div:first-child>ul>li:first-child{
	position: relative;
}
.live-item>div:first-child>ul>li:first-child:before{
	content: "";
    display: inline-block;
    width: 22px;
    height: 18px;
    border-radius: 4px;
    background: #e5338b;
    background-image: url(../images/live2.png);
    background-size: 60%;
    background-position: 50%;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    top: 0px;
}
.live-item>div:first-child>ul>li:nth-child(2){
	color: #7ef0f4;
}
.live-item>div:first-child>ul>li:nth-child(3){
	color: #e86272;
}
.live-item>div:nth-child(2){
	height: 200px;
	align-items: initial;
}
.live-item>div:nth-child(2) .video-img-div{
	position: relative;
}
.live-item>div:nth-child(2) .video-img-div img{
	width: 140px;
    height: 182px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
}
.live-item>div:nth-child(2) .video-img-div .play{
	position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    line-height: 20px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .3215686274509804);
}
.live-item>div:nth-child(2) .video-img-div .play b{
	content: "";
    display: inline-block;
    position: absolute;
    top: 13px;
    left: 26px;
    transform: translate(-50%, -50%);
    height: 0;
    width: 0;
    border-style: solid;
    border-width: 10px;
    border-color: transparent transparent transparent #fff;
    vertical-align: middle;
    margin-top: 8px;
}
.live-item>div:nth-child(2)>ul{
	width: 237px;
	padding-top: 5px;
}
.live-item>div:nth-child(2)>ul>li{
	color: #fff;
}
.live-item>div:nth-child(2)>ul>li:first-child{
	font-size: 15px;
    color: silver;
    margin-bottom: 10px;
}
.live-item>div:nth-child(2)>ul>li:nth-child(2){
	display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.live-item>div:nth-child(2)>ul>li:nth-child(2)>div{
    height: 20px;
    padding: 0 10px;
    display: flex;
	font-size: 12px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin: 5px 20px 5px 0;
}
.live-item>div:nth-child(2)>ul>li:nth-child(2)>div:first-child{
	background-color: #e9335d;
}
.live-item>div:nth-child(2)>ul>li:nth-child(2)>div:nth-child(2){
	background-color: #d14a42;
}
.live-item>div:nth-child(2)>ul>li:nth-child(2)>div:nth-child(3){
	background-color: #285ab7;
}
.live-item>div:nth-child(2)>ul>li:nth-child(2)>div:nth-child(4){
	background-color: #eb46b9;
}
.live-item>div:nth-child(2)>ul>li:nth-child(3){
	width: 170px;
    height: 28px;
    margin-top: 12px;
    text-align: center;
    border-radius: 14px;
    background-image: linear-gradient(90deg, #e34c6f, #ef9163);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.live-item>div:nth-child(2)>ul>li:nth-child(3) img{
	height: 17px;
    margin-right: 5px;
}
.live-item>div:nth-child(2)>ul>li:nth-child(3) span{
	font-size: 14px;
}

.list-item .movie-time-div {
	color: #fff;
	border-radius: 0 0 20px 20px;
	height: 2.3rem;
}


.van-overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: var(--van-overlay-z-index);
	width: 100%;
	height: 100%;
	background-color: #000000;
	opacity: 0.5;
}

.server-dialog {
	position: fixed;
	display: flex;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 330px;
	height: 200px;
	z-index: 16;
	background-color: #262626;
	border: 1px solid #7e6a56;
	border-radius: 10px;
}

.server-dialog ul {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.server-dialog ul>li {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px;
}

.server-dialog ul>li:first-child {
	color: #e6b98a;
	font-size: 18px;
	font-weight: 700;
	margin: 5px;
	border: none;
	height: 26px;
}

.server-dialog ul>li:nth-child(2) {
	font-size: 16px;
	padding: 0 30px;
	color: #fff;
	text-align: center;
	border: none;
	height: 60px;
}

.server-dialog ul>li:nth-child(3) {
	background: linear-gradient(180deg, #efcba7, #dcab76);
	color: #8a5623;
	font-size: 16px;
	width: 177px;
	height: 24px;
	border-radius: 12px;
	border: none;
}

</style>
