<template>
	<div class="container page">
		<van-nav-bar :title="$t('withdraw.with_center')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="main">
			<div class="withdrawMoney">
				<span>{{ $t("withdraw.with_money") }} ({{ $t("reservation.unit") }})</span>
				<div class="money">
					<div class="moneyNumber">
						<span class="moneyType" style="font-size: 18px;margin-right: 10px;">¥</span>
						<van-field v-model="withdraw_money" type="number" />
					</div>
					<span class="all" @click="allMoeny()">{{ $t("index.all") }}</span>
				</div>
				<div class="information">
					<div class="description">
						<van-popover v-model="showPopover" trigger="click">
							<div class="popover-body" style="padding: 10px;">
								<p>1.{{ $t("withdraw.single_limit") }}{{ $t("withdraw.low") }}{{ this.withdrawRole.min }}{{ $t("withdraw.heigh") }}{{ this.withdrawRole.max }}{{ $t("reservation.unit") }}
								</p>
								<p>2.{{ $t("withdraw.with_num") }}{{ this.withdrawRole.num }}{{ $t("withdraw.number") }}</p>
								<p>3.{{ $t("withdraw.with_tip") }}</p>
							</div>
							<!-- <template #reference @click="withdrawInfo()">
								<van-icon name="info-o" />
								{{ $t("withdraw.limit_desc") }}
							</template> -->
						</van-popover>
					</div>

					<div class="balance">
						<span>{{ $t("my.balance") }}：</span>
						<span class="number">{{ this.userInfo.money }}{{ $t("reservation.unit") }}</span>
					</div>
				</div>
				<div class="money">
					<div class="moneyNumber">
						<span class="moneyType" style="font-size: 18px;margin-right: 10px;">资金密码</span>
						<van-field v-model="withdraw_pwd" type="password" />
					</div>
				</div>
			</div>
			<van-button class="withdraw_btn" type="default" @click="doWithdraw()">
				{{ $t("withdraw.immediately_withdraw") }}</van-button>

		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			showPopover: false,
			withdraw_money: "",
			userInfo: {},
			withdrawRole: {},
			withdraw_pwd:''
		};
	},
	methods: {
		back() {
			return window.history.back();
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'user_info'
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
					this.name = res.data.name;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			})
		},
		getUserWithdrawRole() {
			this.$http({
				method: 'get',
				url: 'user_get_withdraw_role'
			}).then(res => {
				if (res.code === 200) {
					this.withdrawRole = res.data;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			})
		},
		allMoeny() {
			this.withdraw_money = this.userInfo.money;
		},
		doWithdraw() {
			if (this.withdraw_money <= 0) {
				this.$toast(this.$t("setting.correct_money"));
				return false;
			}
			if (this.withdraw_pwd == '') {
				this.$toast(this.$t("setting.money_place"));
				return false;
			}
			this.$http({
				method: 'post',
				data: { money: this.withdraw_money,zj_pwd:this.withdraw_pwd },
				url: 'user_set_withdraw'
			}).then(res => {
				if (res.code === 200) {
					location.reload()
					this.$toast(res.msg);
					this.getUserInfo();
					this.getUserWithdrawRole();
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			})
		},
		withdrawInfo() {
			this.showPopover = true;
		}
	},
	created() {
		if (!localStorage.getItem('token')) {
			this.$router.push({ path: '/Login' })
		} else {
			this.getUserInfo();
			this.getUserWithdrawRole();
		}
	}
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";


::v-deep .van-nav-bar {
	height: 50px;
	background: none;
	color: #e6b98a;
}

::v-deep .van-nav-bar__title {
	color: #e6b98a;
}

::v-deep .van-hairline--bottom::after {
	border: none;
}

::v-deep .van-field__control {
	color:#fff;
	padding: 0 10px;
}
.van-cell {
	font-size: 16px;
	line-height: 40px;
	background: #373536;
}

.container p {
	padding: 0 15px;
	margin-top: 15px;
	font-size: 30px;
	color: #dc2037;
}

.container .main {
	display: flex;
	flex-direction: column;
	height: calc(100% - 50px);
	position: relative;
}

.container .main .withdrawMoney {
	display: flex;
	flex-direction: column;
	color: #fff;
	padding: 0 20px;
	white-space: nowrap;
	font-size: 16px;
}

.container .main .withdrawMoney span {
	padding: 10px 0;
}

.container .main .withdrawMoney .money {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid #f2f2f5;
}

.container .main .withdrawMoney .money .moneyNumber {
	font-size: 16px;
	display: flex;
	flex-direction: row;
	flex: 1;
	margin-right: 10px;
}

.container .main .withdrawMoney span {
	padding: 10px 0;
}

.container .main .withdrawMoney .money .all {
	color: #d10404;
}

.container .main .withdrawMoney .money .moneyNumber .van-cell {
	font-size: 16px;
	padding: 0 !important;
}

.container .main .withdrawMoney .information {
	padding-bottom: 30px;
}

.container .main .withdrawMoney .information .description {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 10px 0;
}

.container .main .withdrawMoney span {
	padding: 10px 0;
}

.container .main .withdrawMoney .information .balance .number {
	color: #d10404;
}

.withdraw_btn {
	width: 277px;
    height: 44px;
    border-radius: 22px;
    outline: 0;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    margin: 15px auto;
    background: linear-gradient(#fde3ca, #e7b486);
}
</style>
