<template>
  <van-tabbar
    v-if="show"
    v-model="active"
    active-color="#7e5678"
    :border="true"
    inactive-color="#979799"
  >
  <!-- 首页 -->
    <van-tabbar-item to="/Home">
      <span>{{ $t("foorter.index") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/homes.png' : '/img/footer/home.png'"
          :alt="$t('foorter.index')"
          v-show="show !== 0"
        />
        <img
          :src="props.active ? '/img/footer/homes.png' : '/img/footer/home.png'"
          :alt="$t('foorter.index')"
          :class="$t('foorter.index')"
          style="height: 4rem"
          v-show="show === 0"
        />
      </template>
    </van-tabbar-item>
    <!-- 预约 -->
    <van-tabbar-item to="/Live">
      <span>{{ $t("foorter.live") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/lives.png' : '/img/footer/live.png'"
          :alt="$t('foorter.live')"
          v-show="show !== 1"
        />
        <img
          :src="props.active ? '/img/footer/lives.png' : '/img/footer/live.png'"
          :alt="$t('foorter.live')"
          :class="$t('foorter.live')"
          style="height: 4rem"
          v-show="show === 1"
        />
      </template>
    </van-tabbar-item>
    
    <!-- 视频 -->
    <van-tabbar-item to="/Welfare">
      <span>{{ $t("foorter.welfare") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/fulis.png' : '/img/footer/fuli.png'"
          :alt="$t('foorter.welfare')"
          v-show="show !== 2"
        />
        <img
          :src="props.active ? '/img/footer/fulis.png' : '/img/footer/fuli.png'"
          :alt="$t('foorter.welfare')"
          :class="$t('foorter.welfare')"
          style="height: 4rem"
          v-show="show === 2"
        />
      </template>
    </van-tabbar-item>
    <!-- 我的 -->
    <van-tabbar-item to="/Mine">
      <span>{{ $t("foorter.my") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/users.png' : '/img/footer/user.png'"
          :alt="$t('foorter.my')"
          v-show="show !== 3"
        />
        <img
          :src="props.active ? '/img/footer/users.png' : '/img/footer/user.png'"
          :alt="$t('foorter.my')"
          :class="$t('foorter.my')"
          style="height: 4rem"
          v-show="show === 3"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      active: 0
    };
  },
  methods: {},
  watch: {
    $route(to) {
      if (to.name == "home") {
        this.active = 0;
        this.show = true;
      } else if (to.name == "live") {
        this.active = 1;
        this.show = true;
      } else if (to.name == "welfare") {
        this.active = 2;
        this.show = true;
      } else if (to.name == "mine") {
        this.active = 3;
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  created() {
    if (this.$route.name == "home") {
      this.active = 0;
      this.show = true;
    } else if (this.$route.name == "live") {
      this.active = 1;
      this.show = true;
    } else if (this.$route.name == "welfare") {
      this.active = 2;
      this.show = true;
    } else if (this.$route.name == "mine") {
      this.active = 3;
      this.show = true;
    } else {
      this.show = false;
    }
  },
};
</script>

<style lang="less" scoped>
@tabbar-height: 55px;
@tabbar-img: 27px;
.van-tabbar {
  height: @tabbar-height;
  z-index: 9999;
  background: #1f1c1f;
}
.van-tabbar-item--active{
  background: none;
}
.van-tabbar-item__icon img {
  height: @tabbar-img;
}
.van-tabbar-item {
  font-size: 14px;
}
.tui {
  width: 4rem;
  height: 4rem!important;
  margin-top: -7.333vw;
  background-color: white;
  border-radius: 50%;
  border: 10px solid white;
  z-index: 10;
}
[class*="van-hairline"]::after {
  border: none !important;
}
</style>
