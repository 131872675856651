<template>
	<div class="container page">
		<van-nav-bar class="nav-bar" title="防骗指南">
			<template #left>
				<van-icon name="arrow-left" color="#e6b98a" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="welfare-scroll">
			<div class="scroll-div">
				<ul>
					<li><img src="../images/p1.png" /></li>
					<li>
						<div>
							<p>
								1.本平台所有资源均为实名制，小姐姐与小哥哥皆是VIP会员请对双方需一定的尊重，请不要出言不逊没有素质，经发现将取消约炮资格请谨记！，重要的事情说三遍！！！
							</p>
							<p>2.小姐姐是不会收取任何费用，只需要完成平台三次认证获得约炮名额即可享受同城约炮资源，开房花销需自理哦!</p>
							<p>3.妹子已到楼下,要求付【全款才能上楼】均为骗子无疑，请立即来平台联系接待客服举报！</p>
						</div><img src="../images/p2.png" />
					</li>
					<li><img src="../images/p3.png" />
						<div>
							<p class="warn-p">
								友情提醒：约炮经纪人，售票员，接待员，都不会私加客户的联系方式，非工作时间均不办理业务，若有在下班时间他人给你发送信息，请不要相信避免资金受损我方概不负责！！！</p>
							<p>4.凡是要求下载其他软件的都是骗子,请立即截图来平台联系接待客服举报！</p>
							<p>5.本平台对每一位VIP会员寻欢经历负责,遇到任何问题请及时来平台联系接待客服举报,官方核实后退还解锁消耗!</p>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { Toast } from 'vant';
export default {
	data() {
		return {

		};
	},
	created() {

	},
	methods: {
		back() {
			return window.history.back();
		},

		onRefresh() {
			setTimeout(() => {
				this.finished = false;
				this.loading = true;
				this.onLoad();
				this.isLoading = false;
				Toast(this.$t("reservation.refresh"));
			}, 500);
		}
	}
};
</script>

<style lang='less' scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #16151b;
}

.nav-bar {
	background: none;
	height: 50px;
}

.van-nav-bar {
	line-height: 50px;
}

::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #e6b98a;
	font-size: 18px;
}

::v-deep .van-nav-bar__content {
	height: 50px;
}

::v-deep .van-tab__text--ellipsis {
	overflow: unset;
}

.welfare-scroll {
	width: 100%;
	height: calc(100% - 55px);
	margin: 0 auto;
	overflow-y: auto;
}
.container .scroll-div{
	width: 100%;
    height: calc(100% - 55px);
    overflow-y: auto;
    background-color: #fff;
}
.container .scroll-div ul{
	width: calc(100% - 27px);
    margin: 0 auto;
    height: 100%;
    color: #000;
}
.container .scroll-div ul li:first-child{
	display: flex;
    align-items: center;
    justify-content: center;
    height: 110px;
}
.container .scroll-div ul li:first-child img{
	width: 90%;
    height: 110px;
    -o-object-fit: contain;
    object-fit: contain;
    display: block;
    margin: 0 auto;
}
.container .scroll-div ul li:nth-child(2){
	word-break: break-all;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.container .scroll-div ul li:nth-child(2) div{
	width: calc(100% - 90px);
}
.container .scroll-div ul p{
	color: #000;
    font-size: 16px;
    margin: 0px;
    font-weight: 700;
}
.container .scroll-div ul li:nth-child(2) img{
	width: 90px;
    height: 221px;
    -o-object-fit: contain;
    object-fit: contain;
}
.container .scroll-div ul li:nth-child(3){
	word-break: break-all;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.container .scroll-div ul li:nth-child(3) img{
	width: 90px;
    height: 221px;
    float: right;
    -o-object-fit: contain;
    object-fit: contain;
}
.container .scroll-div ul li:nth-child(3) div{
	width: calc(100% - 110px);
}
.container .scroll-div ul p.warn-p{
	color: #e6b98a;
}
</style>
